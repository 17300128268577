<template>
  <div>
    <NavBar />
    <div id="content">
      <router-view />
    </div>
    <MyFooter />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import MyFooter from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    MyFooter
  },
  mounted() {
    document.title = "Bima Sakti Instrumen";
  }
};
</script>

<style scoped>
/* Tambahkan style di sini jika diperlukan */
</style>

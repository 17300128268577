<template>
  <div class="my-footer">
    <div class="container">
      <footer class="pt-5 pb-1">
        <div class="row">
          <FooterSection title="Product" :links="productLinks" />
          <FooterSection title="Our Services" :links="serviceLinks" />
          <FooterSection title="Research" :links="researchLinks" />
          <div class="col-md-auto">
            <h5 class="text-white">Contact Us</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2" v-for="(contact, index) in contactLinks" :key="index">
                <a :href="contact.href" class="nav-link p-0 text-white">
                  <img :src="contact.imgSrc" :alt="contact.alt" width="24" />&ensp;{{ contact.text }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col">
            <h5 class="text-white">Supported By</h5>
            <img class="img-fluid" :src="supportLogo" style="max-height: 90px; max-width: 90px;">
          </div>
        </div>
        <div class="d-flex justify-content-center py-4 my-4 border-top">
          <p class="text-white">&copy; 2024 Bima Sakti Instrumen. All rights reserved.</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import FooterSection from './FooterSection.vue';

export default {
  name: 'myFooter',
  components: {
    FooterSection
  },
  data() {
    return {
      productLinks: [
        { text: 'Data Acquisition System', href: '#' },
        { text: 'Soil Testing Equipment', href: '#' },
        { text: 'Lab Testing Equipment', href: '#' },
        { text: 'Field Testing Equipment', href: '#' },
        { text: 'Dam Instrumentation', href: '/dam-instrumentation' },
      ],
      serviceLinks: [
        { text: 'Instalation', href: '#' },
        { text: 'Upgrade', href: '#' },
        { text: 'Repair', href: '#' },
        { text: 'Maintenance', href: '#' },
        { text: 'Consultation', href: '#' },
      ],
      researchLinks: [
        { text: 'Image Processing for Triaxial', href: '#' },
        { text: 'Triaxial for Peat Soil', href: '#' },
        { text: 'Modified Oedometer', href: '#' },
        { text: 'Triaxial Lateral Extention', href: '#' },
        { text: 'Local Measurement Triaxial', href: '#' },
      ],
      contactLinks: [
        { text: '+62 851-5505-0947', href: 'https://wa.me/6285155050947', imgSrc: require('../assets/img/common/whatsappLogo.svg'), alt: 'WhatsApp' },
        { text: 'bimasaktiinstrumen@gmail.com', href: 'mailto:bimasaktiinstrumen@gmail.com', imgSrc: require('../assets/img/common/emailLogo.svg'), alt: 'Email' },
        { text: 'Bima Sakti Instrumen', href: 'https://www.linkedin.com/company/bima-sakti-instrumen/', imgSrc: require('../assets/img/common/linkedInLogo.svg'), alt: 'LinkedIn' },
      ],
      supportLogo: require('../assets/img/bsi/Bima Sakti Geotama Logo.png')
    };
  }
}
</script>

<style scoped>
.my-footer {
  background-color: #00295D;
}
</style>

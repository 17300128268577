import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  },
  {
    path: '/Product',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '../views/product/Index.vue')
  },
  {
    path: '/Product/:name',
    name: 'ProductDetail',
    component: () => import('../views/product/Detail.vue')
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactUs.vue')
  },
  {
    path: '/DamInstrumentation',
    name: 'DamInstrumentation',
    component: () => import(/* webpackChunkName: "damInstrumentation" */ '../views/DamInstrumentation.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/errors/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;

<template>
  <div class="col">
    <h5 class="text-white">{{ title }}</h5>
    <ul class="nav flex-column">
      <li v-for="(link, index) in links" :key="index" class="nav-item mb-2">
        <a :href="link.href" class="nav-link p-0 text-white">{{ link.text }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FooterSection',
  props: {
    title: String,
    links: Array
  }
}
</script>

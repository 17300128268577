<template>
  <nav class="navbar navbar-expand-lg" data-bs-theme="dark" style="background-color: #00295D;">
    <div class="container">
      <router-link :to="{ name: 'Home' }">
        <img class="navbar-brand" src="../assets/img/bsi/bimasaktiinstrumen (4).svg" alt="Bima Sakti Instrumen">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav px-3">
          <li class="nav-item" v-for="item in navItems" :key="item.name">
            <router-link :to="{ name: item.routeName }" class="nav-link active">
              {{ item.label }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      navItems: [
        { name: 'AboutUs', routeName: 'AboutUs', label: 'About BSI' },
        { name: 'Product', routeName: 'Product', label: 'Product' },
        { name: 'OurServices', routeName: 'Product', label: 'Our Services' },
        { name: 'Activity', routeName: 'Product', label: 'Activity' },
        { name: 'DamInstrumentation', routeName: 'DamInstrumentation', label: 'Dam Instrumentation' },
        { name: 'ContactUs', routeName: 'ContactUs', label: 'Contact Us' }
      ]
    };
  }
};
</script>
